import {
    createStyles,
    Image,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
    Avatar,
  } from '@mantine/core';
  import { IconCheck } from '@tabler/icons';
  
  const useStyles = createStyles((theme) => ({
    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      // paddingTop: theme.spacing.xl * 4,
      paddingBottom: theme.spacing.xl * 4,
    },
  
    content: {
      maxWidth: 600,
      marginRight: theme.spacing.xl * 3,
  
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        marginRight: 0,
      },
    },
  
    title: {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: 44,
      lineHeight: 1.2,
      fontWeight: 900,
  
      [theme.fn.smallerThan('xs')]: {
        fontSize: 28,
      },
    },
  
    control: {
      [theme.fn.smallerThan('xs')]: {
        flex: 1,
      },
    },
  
    image: {
      flex: 1,
  
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },
  
    highlight: {
      position: 'relative',
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      borderRadius: theme.radius.sm,
      padding: '4px 12px',
    },
  }));
  
  export default function Hero() {
    const { classes } = useStyles();
    return (
      <div>
        <Container>
          <div className={classes.inner}>
            <div className={classes.content}>
              <Title className={classes.title}>
                A <span className={classes.highlight}>dedicated</span> FullStack<br /> developer
              </Title>
              <Text color="dimmed" mt="md">
                About 10 year experience, working with remote and distributed teams from around the world, acoss different industries,
                I have worked with amazing teams and built impactful web apps which has given me tremendous experience, work ethic, and resolve.
              </Text>
  
              <List
                mt={30}
                spacing="sm"
                size="sm"
                icon={
                  <ThemeIcon size={20} radius="xl">
                    <IconCheck size={12} stroke={1.5} />
                  </ThemeIcon>
                }
              >
                <List.Item>
                  <b>Frontend</b> – I build type safe applications with react or Vue.
                </List.Item>
                <List.Item>
                  <b>Backend </b> – I am proficient in NodeJs and PHP/Laravel, with minimal Python skills
                </List.Item>
                <List.Item>
                  <b>Cloud</b> – I{"'"}ve worked with Firebase, AWS, Lambda functions, websockets, e.t.c
                </List.Item>
              </List>
  
              <Group mt={30}>
                <a href="mailto:ignatiusyesigye@gmail.com" target="_blank">
                  <Button radius="xl" size="md" className={classes.control}>
                    Hire me
                  </Button>
                </a>
                <a href="https://www.linkedin.com/in/ignatius-yesigye/" target="_blank">
                  <Button variant="default" radius="xl" size="md" className={classes.control}>
                    LinkedIn
                  </Button>
                </a>
              </Group>
            </div>
            {/* <Image src='/me.png' width={300} height={300} alt="avatar" /> */}
            <Avatar src="/me.png" size={250} radius={250} alt="ignatius" />
          </div>
        </Container>
      </div>
    );
  }