import { useState } from 'react';
import { createStyles, Header, Group, ActionIcon, Container, Burger, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconBrandTwitter, IconBrandLinkedin, IconBrandGithub } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,

    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'flex-start',
    },
  },

  links: {
    width: 260,

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  social: {
    width: 260,

    [theme.fn.smallerThan('sm')]: {
      width: 'auto',
      marginLeft: 'auto',
    },
  },

  burger: {
    marginRight: theme.spacing.md,

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}));

interface HeaderTopProps {
  links: { link: string; label: string }[];
}

export default function HeaderTop({ links }: HeaderTopProps) {
  const [opened, { toggle }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const { classes, cx } = useStyles();

  const items = links.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={cx(classes.link, { [classes.linkActive]: active === link.link })}
      onClick={(event) => {
        event.preventDefault();
        setActive(link.link);
      }}
    >
      {link.label}
    </a>
  ));

  return (
    <Header height={56} mb={120}>
      <Container className={classes.inner}>
        <Burger opened={opened} onClick={toggle} size="sm" className={classes.burger} />
        <Group className={classes.links} spacing={5}>
          {items}
        </Group>

        <div>
          <img src='/meTiny.png' alt="avatar" style={{borderRadius:"100%",width:35,height:35}} />
          <Text fz="md" fw={700} span style={{marginLeft:15}}>Ignatius</Text>
        </div>

        <Group spacing={0} className={classes.social} position="right" noWrap>
          <a href="https://twitter.com/iyesigye" target="_blank">
            <ActionIcon size="lg">
              <IconBrandTwitter/>
            </ActionIcon>
          </a>
          <a href="https://www.linkedin.com/in/ignatius-yesigye/" target="_blank">
            <ActionIcon size="lg">
              <IconBrandLinkedin/>
            </ActionIcon>
          </a>
          <a href="https://github.com/yesigye" target="_blank">
            <ActionIcon size="lg">
              <IconBrandGithub/>
            </ActionIcon>
          </a>
        </Group>
      </Container>
    </Header>
  );
}